import axios from 'axios';
import { Dispatch } from 'redux';

// Utils
import getEndpoint from '../../utils/endpoints';

function maintenancePageSuccess(data: any) {
  return {
    type: 'page/MAINTENANCE_PAGE_SUCCESS' as const,
    data,
  };
}

function maintenancePageFailure(error: unknown) {
  return {
    type: 'page/MAINTENANCE_PAGE_FAILURE' as const,
    error,
  };
}

function maintenanceLoading() {
  return {
    type: 'page/MAINTENANCE_LOADING' as const,
  };
}

export function fetchMaintenancePage(lang: string) {
  const unifiedLang = lang.replace('-', '_').toUpperCase();
  return async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    const endpoint = `${getEndpoint('maintenance', state)}?lang=${unifiedLang}`;
    dispatch(maintenanceLoading());
    try {
      const { data } = await axios.get(endpoint);
      dispatch(maintenancePageSuccess(data));
    } catch (e) {
      dispatch(maintenancePageFailure(e));
    }
  };
}

export type MaintenanceAction =
  | ReturnType<typeof maintenancePageSuccess>
  | ReturnType<typeof maintenancePageFailure>
  | ReturnType<typeof maintenanceLoading>;
