'use client';

import { useSelector } from 'react-redux';
import { usePathname } from 'next/navigation';

// uTils
import {
  webshopMaintenanceEnabledSelector,
  webshopMaintenanceLoadingSelector,
} from 'utils/selectors/maintenanceSelectors';
import { webshopLandingpageUrlSelector } from 'utils/selectors/globalsSelectors';

export function useWebshopMaintenance() {
  const maintenanceEnabled = useSelector(webshopMaintenanceEnabledSelector);
  const maintenanceLoading = useSelector(webshopMaintenanceLoadingSelector);
  const webshopHomepage = useSelector(webshopLandingpageUrlSelector);
  const pathname = usePathname();

  return pathname === webshopHomepage && maintenanceEnabled && !maintenanceLoading;
}
