import { useSelector } from 'react-redux';

// utils
import {
  webshopBlockedCheckoutPageUrlSelector,
  webshopCheckoutUrlSelector,
} from 'utils/selectors/globalsSelectors';

export function useRedirectUrl(role: string | undefined, pageType?: string) {
  const checkoutBlockedUrl = useSelector(webshopBlockedCheckoutPageUrlSelector);
  const checkoutUrl = useSelector(webshopCheckoutUrlSelector);

  // only b2c users are allowed to see the checkout and checkout intro page
  if (
    role &&
    role !== 'B2C' &&
    pageType &&
    ['checkout_intro', 'checkout'].includes(pageType) &&
    checkoutBlockedUrl
  ) {
    return checkoutBlockedUrl;
  }

  if (role === 'B2C' && pageType && ['checkout_intro'].includes(pageType) && checkoutUrl) {
    return checkoutUrl;
  }

  return undefined;
}
