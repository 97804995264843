// components
import Footer from 'components/Footer/Footer';
import Maintenance from 'components/Maintenance/maintenance';

export default function MaintenancePage() {
  return (
    <>
      <main>
        <Maintenance />
      </main>
      <Footer />
    </>
  );
}
