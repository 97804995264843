import { trackTemplate } from '../../utils/tracking';

/**
 * Accordion Click
 * @param string pathname Url pathname of current page
 * @param string scrollDepthVar Scroll depth
 */
export function scrollDepth(pathname, scrollDepthVar) {
  return trackTemplate({
    event: 'scrollEvent',
    data: {
      category: 'Scroll Depth',
      action: pathname,
      label: `${scrollDepthVar}%`,
      value: '0',
    },
  });
}
