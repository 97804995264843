import { useContext, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

// utils
import { SET_SESSION, SessionContext } from '../Session/SessionContext';

export function useSessionId() {
  const {
    state: { session },
    dispatch: sessionDispatch,
  } = useContext(SessionContext) || { state: {}, dispatch: () => {} };

  useEffect(() => {
    if (!session) {
      const storedSession = window.localStorage.getItem('gsessid');
      if (storedSession) {
        sessionDispatch({ type: SET_SESSION, session: storedSession });
      } else {
        const newSessionId = uuidv4();
        window.localStorage.setItem('gsessid', newSessionId);
        sessionDispatch({ type: SET_SESSION, session: newSessionId });
      }
    }
  }, [session, sessionDispatch]);
}
