import { useSelector } from 'react-redux';

// styles
import styles from './maintenance.module.scss';

// components
import { InnerHtml } from '../InnerHtml/inner-html';
import { BUTTON_BACKGROUND, BUTTON_COLORS, Button } from '../Button/Button';
import Separator from '../ContentElements/Form/fields/separator/separator';
import { Title } from '../ContentElements/Title/Title';
import { CmsLink } from '../Link/CmsLink';

// utils
import { webshopMaintenancePageSelector } from '../../utils/selectors/maintenanceSelectors';
import { useIsMobile } from 'components/App/SizeProvider';

export default function Maintenance() {
  const isMobile = useIsMobile();
  const {
    headline,
    subheadline,
    image,
    text,
    downtimeTitle,
    startDate,
    endDate,
    startTime,
    endTime,
    buttonText,
    startImmediately,
    timeKey,
    imageTitle,
  } = useSelector(webshopMaintenancePageSelector) ?? {};

  const toHomepage = {
    target: '/',
    type: 'internal_link',
    showArrow: false,
  };

  return (
    <div
      style={{ backgroundImage: !isMobile ? `url(${image})` : '' }}
      role="img"
      aria-label={imageTitle}
      title={imageTitle}
      className={styles.maintenancePage}
    >
      <div className="grid-container">
        <div className="grid-x">
          <div className={`cell small-12 medium-8 large-5 ${styles.maintenanceLayer}`}>
            <Title title={headline} subtitle={subheadline} Format="h2" />

            {!startImmediately && downtimeTitle && (
              <InnerHtml className={styles.downTimeTitle} as="b" content={downtimeTitle} />
            )}
            {!startImmediately && (
              <>
                <div className={styles.downtimeWrapper}>
                  <div className={styles.startDate}>
                    {startDate && <InnerHtml as="b" content={startDate} />}
                    {startTime && (
                      <div>
                        <InnerHtml as="span" content={startTime} />{' '}
                        <InnerHtml as="span" content={timeKey} />
                      </div>
                    )}
                  </div>
                  <div className={styles.hyphen}>-</div>
                  <div className={styles.endDate}>
                    {endDate && <InnerHtml as="b" content={endDate} />}
                    {endTime && (
                      <div>
                        <InnerHtml as="span" content={endTime} />{' '}
                        <InnerHtml as="span" content={timeKey} />
                      </div>
                    )}
                  </div>
                </div>
                <Separator />
              </>
            )}

            {text && <InnerHtml className={styles.information} as="p" content={text} />}
            {buttonText && (
              <CmsLink link={toHomepage}>
                {/* @ts-ignore */}
                <Button
                  symbol="arrow-link-light-right"
                  background={BUTTON_BACKGROUND.PRIMARY}
                  color={BUTTON_COLORS.WHITE}
                >
                  {buttonText}
                </Button>
              </CmsLink>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
