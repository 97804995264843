import { useReducer, createContext } from 'react';
import produce from 'immer';

// utils
import { useContextValue } from 'utils/hooks/use-context-value';

export const SET_TOC_DATA = 'SET_TOC_DATA';

interface State {
  tocData: readonly { id: string }[];
}

const initialState = { tocData: [] };

export const ContentContext = createContext<{
  state: State;
  dispatch: React.Dispatch<any>;
}>({
  state: initialState,
  dispatch: () => null,
});

const contentReducer = produce((draft, action) => {
  if (action.type === SET_TOC_DATA) {
    draft.tocData = action.tocData;
  }
}, initialState);

export function ContentContextProvider({ children }: Readonly<React.PropsWithChildren>) {
  const reducer = useReducer(contentReducer, initialState);
  const value = useContextValue(reducer);

  return <ContentContext.Provider value={value}>{children}</ContentContext.Provider>;
}
