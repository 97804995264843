import { createContext, useContext } from 'react';
import { scenes } from '../components/Page/scenes';

export type ParentContextType =
  | {
      parent: 'page';
      pageType: keyof typeof scenes | 'job_search';
    }
  | {
      parent: 'contentArea' | undefined;
    };

const ParentContext = createContext<ParentContextType>({
  parent: undefined,
});

export const ParentProvider = ParentContext.Provider;

export function useGetParent() {
  return useContext(ParentContext);
}
