import { useRef, createContext } from 'react';

type PrevLocationProps = React.MutableRefObject<{ search?: string }>;

export const PrevLocationContext = createContext({} as PrevLocationProps);

export function PrevLocationContextProvider({ children }: React.PropsWithChildren) {
  const prevLocation = useRef({});

  return (
    <PrevLocationContext.Provider value={prevLocation}>{children}</PrevLocationContext.Provider>
  );
}
