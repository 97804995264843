import { Divider } from '@geberit/gdds';

// utils
import { useGdds } from 'utils/hooks/use-gdds';

function Separator() {
  const isGdds = useGdds();

  if (isGdds) {
    return <Divider />;
  }

  return <hr />;
}

export default Separator;
