export const PAGES_WHERE_COOKIE_BANNER_IS_NOT_DISPLAYED = [
  'm-impressum.html',
  'm-disclaimer.html',
  'm-data-privacy.html',
  // geberit.com - de
  'impressum/',
  'rechtshinweise/',
  'datenschutzerklaerung/',
  // geberit.com - en
  'imprint/',
  'disclaimer/',
  'data-privacy-statement/',
  // ifo.dk
  'impressum.html',
  'juridisk-information.html',
  'databeskyttelseserklaering.html',
  // ifo.se
  'utgivare.html',
  'raettsligt.html',
  'datasekretess.html',
  // ifo.ee
  'impressum.html',
  'privaatsuspoliitika/',
  'igusteave.html',
  // ifo.lv
  'privatuma-politika/',
  'juridisk-s-atsauces.html',
  'juridisk-inform-cija.html',
  // ifo.lt
  'teisine-informacija/',
  'teisines-nuorodos/',
  'privatumo-politika/',
  // ido.fi
  'tietosuojalauseke/',
  'lausunnot/',
  'vastuuvapauslauseke/',
  // porsgrundbad.no
  'impressum.html',
  'ansvarsfraskrivelse.html',
  'juridisk-informasjon.html',
  // kolo.ua
  'pravova-informacija/',
  'impresum/',
  'zakhyst-personalnykh-danykh/',
  // kolo.com.pl
  'stopka-redakcyjna.html',
  'wskaz-wki-prawne.html',
  'data-privacy.html',
  // kolo-geberit.hu
  'adatvedelmi-nyilatkozat.html',
  'adatvedelmi-nyilatkozat.html',
  // twyfordbathrooms.com
  'imprint.html',
  'disclaimer.html',
  'data-privacy.html',
];
