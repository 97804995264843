import { useReducer, createContext } from 'react';
import produce from 'immer';

// utils
import { useContextValue } from '../../utils/hooks/use-context-value';

export const UPDATE_ACCORDION_STATE = 'UPDATE_ACCORDION_STATE';

interface State {
  [key: string]: string;
}

const initialState = {};

export const EditingContext = createContext<{
  state: State;
  dispatch: React.Dispatch<any>;
}>({
  state: initialState,
  dispatch: () => null,
});

const editingReducer = produce((draft, action) => {
  if (action.type === UPDATE_ACCORDION_STATE) {
    draft[action.previewId] = action.accordionIndex;
  }
}, initialState);

export function EditingProvider({ children }: { readonly children: React.ReactNode }) {
  const reducer = useReducer(editingReducer, initialState);
  const value = useContextValue(reducer);

  return <EditingContext.Provider value={value}>{children}</EditingContext.Provider>;
}
